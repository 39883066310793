const images = {
  backgroundHero: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/leilao-hero-360/image.webp',
    md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/leilao-hero-768/image.webp',
    lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/leilao-hero-1024/image.webp',
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/leilao-hero-1440/image.webp',
  },
}

export { images }
