import styled from 'styled-components'

import { images } from '../../assets/img/_images'
import { device } from 'src/styles/breakpoints'

interface IImages {
  [key: string]: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
}

const { backgroundHero }: IImages = images

export const LeilaoDeCarteirasDeCretidoSection = styled.section`
  background-image: url(${backgroundHero.sm});
  height: 620px;
  background-size: cover;
  padding: 56px 0;

  @media ${device.tablet} {
    background-image: url(${backgroundHero.md});
    height: 538px;
  }

  @media ${device.desktopLG} {
    background-image: url(${backgroundHero.lg});
    height: 586px;
  }

  @media ${device.desktopXL} {
    background-image: url(${backgroundHero.xl});
    height: 800px;
  }

  h1 {
    color: white;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;

    @media ${device.tablet} {
      font-size: 48px;
      line-height: 52px;
    }

    @media ${device.desktopLG} {
      font-size: 72px;
      line-height: 80px;
    }

    @media ${device.desktopXL} {
      font-size: 104px;
      line-height: 114px;
    }
  }

`
