import React from 'react'
import { Link } from 'gatsby'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './style'
import { ButtonLink } from 'src/components/ButtonLink'
import { orange, white } from 'src/styles/colors'
import { WIDTH_MD } from 'src/styles/breakpoints'

const AcesseOsEditais = () => {
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.AcesseOsEditaisSection>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-28 lh-34 fs-md-40 lg-md-44 text-grayscale--500 fw-500 font-citrina mb-4 pb-2'>Acesse os editais</h2>
            <p className='fs-18 lh-22 fs-md-24 lh-md-28 text-grayscale--500 fw-500 f-inter mb-3 mb-md-4'>FGO - Desenrola Brasil</p>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 f-inter mb-4'>O presente leilão visa à cessão da carteira de créditos do programa Desenrola Brasil do BANCO INTER S.A., através da plataforma especializada Debitumx.</p>
            <div className='d-flex flex-column flex-md-row gap-between'>
              <ButtonLink
                as={Link}
                to='https://www.debitumx.com/'
                className='mb-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar leilão',
                    section_name: 'Acesse os editais',
                    redirect_url: 'https://www.debitumx.com/',
                  })
                }}
              >
                Acessar leilão
                {windowWidth >= WIDTH_MD ? <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' size='LG' color={white} /> : null}
              </ButtonLink>
              {/* <ButtonLink
                href='https://marketing.bancointer.com.br/arquivos/pdf/credito/Edital-primeiro-Leilao-desenrola-brasil-assinado.pdf'
                target='_blank'
                rel='noreferrer'
                outline
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Acessar edital',
                    section_name: 'Acesse os editais',
                    redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/credito/Edital-primeiro-Leilao-desenrola-brasil-assinado.pdf',
                  })
                }}
              >
                Acessar edital
                {windowWidth >= WIDTH_MD ? <IconsSwitch icon='ic_chevron_right' lib='interco' customPath='action-navigation/' size='LG' color={orange[500]} /> : null}
              </ButtonLink> */}
            </div>
          </div>
        </div>
      </div>
    </S.AcesseOsEditaisSection>
  )
}

export default AcesseOsEditais
