import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const AcesseOsEditaisSection = styled.section`
  h2 {
    margin-bottom: 32px;
    @media ${device.desktopXL} {
      margin-bottom: 80px;
    }
  }

  .gap-between {
    gap: 32px;
  }

  padding: 40px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  a {
    margin-bottom: 0 !important;
    @media ${device.tablet} {
      width: 276px;
      justify-content: space-between;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`
