import React from 'react'

import * as S from './style'

const HeroLeilaoDeCarteirasDeCretido = () => {
  return (
    <S.LeilaoDeCarteirasDeCretidoSection className='d-flex align-items-md-center' role='img' aria-label='Cliente Inter Empesas sorrindo enquanto segura seu cartão PJ e acessa seu notebook.'>
      <div className='container d-flex d-md-block align-items-end'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6'>
            <h1>Leilões de <span className='d-block'>carteiras de</span> crédito</h1>
          </div>
        </div>
      </div>
    </S.LeilaoDeCarteirasDeCretidoSection>
  )
}

export default HeroLeilaoDeCarteirasDeCretido
